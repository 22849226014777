table.dataTable thead > tr > th {
  background-color: rgb(63, 81, 181);
  font-weight: 500;
  padding: 5px;
  padding-bottom: 10px !important;
  vertical-align: top;
  color: #fff;
}

table.dataTable thead > tr > th:first-child {
  border-top-left-radius: 6px;
}

table.dataTable thead > tr > th:last-child {
  border-top-right-radius: 6px;
}

table.dataTable thead > tr > th.sorting {
  position: relative;
  /*padding-left: 15px;*/
}
table.dataTable thead > tr > th.sorting > .label {
  padding-left: 15px;
}

table.dataTable thead > tr > th.sorting_asc > .label,
table.dataTable thead > tr > th.sorting_desc > .label {
  font-weight: 700;
}

table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting:after {
  position: absolute;
  display: block;
  opacity: 0.5;
  left: 6px;
  line-height: 9px;
  font-size: 0.9em;
  font-weight: 900;
}
table.dataTable thead > tr > th.sorting:before {
  top: 5px;
  content: '▴';
}
table.dataTable thead > tr > th.sorting:after {
  top: 13px;
  content: '▾';
}
table.dataTable thead > tr > th.sorting_asc:before,
table.dataTable thead > tr > th.sorting_desc:after {
  opacity: 0.9;
}

table.dataTable thead > tr > th.sorting_desc_disabled:after,
table.dataTable thead > tr > th.sorting_asc_disabled:before {
  display: none;
}
table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none;
}

table.dataTable.nowrap th,
table.dataTable.nowrap td {
  white-space: nowrap;
}
table.dataTable th.dt-left,
table.dataTable td.dt-left {
  text-align: left;
}
table.dataTable th.dt-center,
table.dataTable td.dt-center,
table.dataTable td.dataTables_empty {
  text-align: center;
}
table.dataTable th.dt-right,
table.dataTable td.dt-right {
  text-align: right;
}
table.dataTable th.dt-justify,
table.dataTable td.dt-justify {
  text-align: justify;
}
table.dataTable th.dt-nowrap,
table.dataTable td.dt-nowrap {
  white-space: nowrap;
}
table.dataTable thead th,
table.dataTable thead td,
table.dataTable tfoot th,
table.dataTable tfoot td {
  text-align: left;
}
table.dataTable thead th.dt-head-left,
table.dataTable thead td.dt-head-left,
table.dataTable tfoot th.dt-head-left,
table.dataTable tfoot td.dt-head-left {
  text-align: left;
}
table.dataTable thead th.dt-head-center,
table.dataTable thead td.dt-head-center,
table.dataTable tfoot th.dt-head-center,
table.dataTable tfoot td.dt-head-center {
  text-align: center;
}
table.dataTable thead th.dt-head-right,
table.dataTable thead td.dt-head-right,
table.dataTable tfoot th.dt-head-right,
table.dataTable tfoot td.dt-head-right {
  text-align: right;
}
table.dataTable thead th.dt-head-justify,
table.dataTable thead td.dt-head-justify,
table.dataTable tfoot th.dt-head-justify,
table.dataTable tfoot td.dt-head-justify {
  text-align: justify;
}
table.dataTable thead th.dt-head-nowrap,
table.dataTable thead td.dt-head-nowrap,
table.dataTable tfoot th.dt-head-nowrap,
table.dataTable tfoot td.dt-head-nowrap {
  white-space: nowrap;
}
table.dataTable tbody th.dt-body-left,
table.dataTable tbody td.dt-body-left {
  text-align: left;
}
table.dataTable tbody th.dt-body-center,
table.dataTable tbody td.dt-body-center {
  text-align: center;
}
table.dataTable tbody th.dt-body-right,
table.dataTable tbody td.dt-body-right {
  text-align: right;
}
table.dataTable tbody th.dt-body-justify,
table.dataTable tbody td.dt-body-justify {
  text-align: justify;
}
table.dataTable tbody th.dt-body-nowrap,
table.dataTable tbody td.dt-body-nowrap {
  white-space: nowrap;
}

table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}
table.dataTable td,
table.dataTable th {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
  text-align: center;
}
table.dataTable.nowrap th,
table.dataTable.nowrap td {
  white-space: nowrap;
}
table.dataTable.table-striped > tbody > tr:nth-of-type(2n + 1) > * {
  box-shadow: none;
}
table.dataTable > tbody > tr {
  background-color: transparent;
}
table.dataTable > tbody > tr.selected > * {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.9);
  color: white;
}
table.dataTable.table-striped > thead > tr > th {
  padding-left: 7px;
}

table.dataTable.table-striped > tbody > tr > td {
  padding: 4px;
  font-size: 16px;
}
table.dataTable.table-striped > tbody > tr.odd > * {
  box-shadow: inset 0 0 0 9999px rgba(63, 81, 181, 0.05);
}
table.dataTable.table-striped > tbody > tr.odd.selected > * {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.95);
}
table.dataTable.table-hover > tbody > tr:hover > * {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.075);
}
table.dataTable.table-hover > tbody > tr.selected:hover > * {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.975);
}
div.dataTables_wrapper div.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
}
div.dataTables_wrapper div.dataTables_length select {
  width: auto;
  display: inline-block;
}
div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
}
div.dataTables_wrapper div.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
}
div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}
div.dataTables_wrapper div.dataTables_info {
  padding-top: 0.85em;
}
div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: flex-end;
}

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_length,
  div.dataTables_wrapper div.dataTables_filter,
  div.dataTables_wrapper div.dataTables_info,
  div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
  }
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    justify-content: center !important;
  }
}

table.dataTable.table-sm > thead > tr > th:not(.sorting_disabled) {
  padding-right: 20px;
}
table.table-bordered.dataTable {
  border-right-width: 0;
}
table.table-bordered.dataTable thead tr:first-child th,
table.table-bordered.dataTable thead tr:first-child td {
  border-top-width: 1px;
}
table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
  border-left-width: 0;
}
table.table-bordered.dataTable th:first-child,
table.table-bordered.dataTable th:first-child,
table.table-bordered.dataTable td:first-child,
table.table-bordered.dataTable td:first-child {
  border-left-width: 1px;
}
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable td:last-child {
  border-right-width: 1px;
}
table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
  border-bottom-width: 1px;
}

div.table-responsive > div.dataTables_wrapper > div.row {
  margin: 0;
}
div.table-responsive > div.dataTables_wrapper > div.row > div[class^='col-']:first-child {
  padding-left: 0;
}
div.table-responsive > div.dataTables_wrapper > div.row > div[class^='col-']:last-child {
  padding-right: 0;
}

.cursor-pointer {
  cursor: pointer;
}

/* Force table to not be like tables anymore */
/*table, thead, tbody, th, td, tr {*/
table.table-res,
table.table-res thead,
table.table-res tbody,
table.table-res th,
table.table-res td,
table.table-res tr {
  display: block;
}

/* Hide table headers (but not display: none;, for accessibility) */
table.table-res thead tr {
  /*position: absolute;*/
  /*top: -9999px;*/
  /*left: -9999px;*/
}

table.table-res thead > tr > th:first-child {
  border-top-right-radius: 6px;
}

table.table-res thead > tr > th:last-child {
  border-top-right-radius: 0px !important;
}


table.table-res tr {
  /*margin: 0 0 1rem 0;*/
}

table.table-res td {
  /* Behave  like a "row" */
  border: none;
  border-bottom: 1px solid #eee;
  position: relative;
  padding-left: 40% !important;
  min-height: 24px;
}

table td span.label {
  display: none;
}

table.table-res td span.label {
  display: block;
  /* Now like a table header */
  position: absolute;
  /* Top/left values mimic padding */
  top: 0;
  left: 6px;
  width: 35%;
  padding-right: 10px;
  white-space: nowrap;
  font-weight: bold;
}
